<template>
    <div class="about">
      <h1>About iMonster</h1>
      <p>iMonster is your comprehensive Pokémon card inventory management system.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'About'
  }
  </script>
  
  <style scoped>
  .about {
    padding: 20px;
  }
  </style>